<template>
  <div id="login">
    <img id="logo" src="../assets/logo.png" />
    <h1>Login</h1>
    <input ref="user" type="text" placeholder="Benutzer" v-model="username" />
    <input ref="pass" type="password" placeholder="Passwort" v-model="password" v-on:keyup.enter="login" />
    <div v-on:click="login" class="submitButton">Einloggen</div>
  </div>
</template>

<script>
import axios from 'axios'
import sha256 from 'crypto-js/sha256'

import Konstanten from '../scripts/Konstanten'

export default {
  name: 'LoginView',

  mixins: [Konstanten],

  data () {
    return {
      username: '',
      password: ''
    }
  },

  methods: {
    login: function () {
      if (this.username.length >= 3 && this.password.length >= 3) {
        const hash = sha256(this.password)
        axios.post(this.getFunctionsURL(), {
          function: 'login',
          username: this.username,
          password: hash
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).then(response => {
          if (response.data === 'Falsche Zugangsdaten') {
            alert('Zugangsdaten falsch.')
          } else if (response.data.msg === 'Wartung') {
            alert('Wartungspause')
          } else if (response.data.msg === 'Erfolgreich eingeloggt') {
            localStorage.username = this.username
            localStorage.password = hash
            localStorage.userAccounts = JSON.stringify(response.data.userAccounts)
            localStorage.userAccountActive = 0
            this.$router.go('Dashboard')
          }
        })
      }
    }
  },

  mounted: function () {
    if (localStorage.username != null) {
      this.username = localStorage.username
      this.$refs.pass.focus()
    } else {
      this.$refs.user.focus()
    }
  }
}
</script>

<style scoped>
#login {
  text-align: center;
  padding: 1em;
  width: calc(100vw - 2em);
  height: calc(100vh - 4em);
  overflow: hidden auto;
}

#logo {
  max-width: 50%;
  max-height: 30vh;
}

input {
  width: calc(100% - 1em - 20px);
  padding: 0.5em;
  font-size: 1em;
  font-family: Anybody Light;
  border: 10px solid var(--magenta);
  border-radius: 1em;
  margin-top: 0.5em;
}
</style>
