<template>
  <div id="post">
    <div id="toast">Gespeichert...</div>
    <h1>Follower</h1>
    <div id="calendarBorder" class="contentBox">
      <h2>Datum</h2>
      <input v-model="followerDate" type="date" />
      <h2>Account</h2>
      <select v-model="followerAccount">
        <option v-for="account, idx in accounts" :key="idx" :value="idx">{{account}}</option>
      </select>
      <h2>Follower</h2>
      <input v-model="followerCount" type="number" />
      <div v-on:click="store" class="submitButton">Speichern</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import Konstanten from '../scripts/Konstanten'
import globalFunctions from '../scripts/globalFunctions'

export default {
  name: 'FollowerView',

  mixins: [Konstanten, globalFunctions],

  data () {
    return {
      followerDate: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
      followerAccount: 0,
      followerCount: '',
      laden: false,
      accounts: [
        'juliasachsen',
        'fdp_sn',
        'hai.happen_',
        'julia.dresden',
        'julisleipzig',
        'julis_chemnitz',
        'julis_vogtland']
    }
  },

  methods: {
    store: function (goBack = true) {
      if (this.laden || this.followerDate === '' || this.followerAccount === '' || this.followerCount === '') return null
      this.laden = true
      axios.post(this.getFunctionsURL(), {
        function: 'storeFollowerCount',
        username: localStorage.username,
        password: localStorage.password,
        followerDate: this.followerDate,
        followerAccount: this.accounts[this.followerAccount],
        followerCount: this.followerCount
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data === 'Falsche Zugangsdaten') {
          alert('Zugangsdaten falsch.')
          localStorage.removeItem('password')
        } else {
          if (goBack) {
            this.$router.push('/')
          } else {
            this.followerAccount = (this.followerAccount + 1) % this.accounts.length
            this.followerCount = null
            const x = document.getElementById('toast')
            x.classList.add('show')
            setTimeout(function () { x.classList.remove('show') }, 2500)
          }
        }
      }).then(() => {
        this.laden = false
      })
    }
  },

  mounted: function () {
    if (localStorage.username !== 'Sebastian') {
      this.$router.go(-1)
    }

    onkeydown = (e) => {
      if (e.ctrlKey && e.keyCode === 'S'.charCodeAt(0)) {
        e.preventDefault()
        this.store(false)
      }
    }
  }
}
</script>

<style scoped>
#calendarBorder {
  width: calc(100% - 20px - 1em);
  height: calc(100vh - 2em - 2.2em - 2em - 1em - 20px);
  padding: 0.5em;
  user-select: none;
  overflow: hidden auto;
}

#calendarBorder h2 {
  font-size: 1em;
}

#calendarBorder input,
#calendarBorder select,
#calendarBorder textarea {
  width: calc(100% - 0.4em);
  padding: 0.2em 0.2em 0 0.2em;
  border-color: var(--softMagenta);
  border-style: solid;
  border-width: 0 0 3px 0;
  outline: none;
  font-family: Anybody Light;
  margin-bottom: 0.5em;
  font-size: 0.9em;
  background: var(--white);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

#calendarBorder input:disabled,
#calendarBorder select:disabled,
#calendarBorder textarea:disabled {
  color: inherit;
}

#calendarBorder input:focus,
#calendarBorder select:focus,
#calendarBorder textarea:focus {
  border-color: var(--magenta);
  background: var(--softMagenta);
}

#toast {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  max-width: 50%;
  background-color: var(--yellow);
  color: var(--magenta);
  font-family: Anybody ExtraBold;
  text-align: center;
  border-radius: 0.5em;
  padding: 0.5em;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: calc(2em / 3 + 10px);
  font-size: 1.5em;
  user-select: none;
  cursor: wait;
}

#toast.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2s;
  animation: fadein 0.5s, fadeout 0.5s 2s;
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: calc(2em / 3 + 10px); opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: calc(2em / 3 + 10px); opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: calc(2em / 3 + 10px); opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: calc(2em / 3 + 10px); opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

</style>
