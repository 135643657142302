<template>
  <div id="password">
    <h1>Passwort ändern</h1>
    <div id="passwordBorder" class="contentBox">
      <h2>altes Passwort</h2>
      <input type="password" v-model="oldPassword" />
      <h2>neues Passwort:</h2>
      <input type="password" v-model="newPasswordI" />
      <h2>neues Passwort wiederholen:</h2>
      <input type="password" v-model="newPasswordII" v-on:keyup.enter="changePassword" />
      <div class="submitButton" v-on:click="changePassword">Passwort ändern</div>
    </div>
  </div>
</template>

<script>
import sha256 from 'crypto-js/sha256'
import axios from 'axios'

import Konstanten from '../scripts/Konstanten'

export default {
  name: 'PasswordView',

  mixins: [Konstanten],

  components: {

  },

  data () {
    return {
      oldPassword: '',
      newPasswordI: '',
      newPasswordII: ''
    }
  },

  methods: {
    changePassword: function () {
      if (this.newPasswordI === this.newPasswordII) {
        if (this.newPasswordI.length >= 6) {
          const oldHash = sha256(this.oldPassword)
          const newHash = sha256(this.newPasswordI)
          axios.post(this.getFunctionsURL(), {
            function: 'changePassword',
            username: localStorage.username,
            password: oldHash,
            newPassword: newHash
          }, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }).then(response => {
            if (response.data.length === 0) {
              localStorage.password = newHash
              alert('Passwort erfolgreich geändert.')
              this.$router.go(-1)
            } else {
              alert('Altes Passwort stimmt nicht.')
            }
          })
        } else {
          alert('Das Passwort muss mindestens 6 Zeichen lang sein.')
        }
      } else {
        alert('Passwörter stimmen nicht überein.')
      }
    }
  },

  computed: {

  },

  mounted: function () {

  }
}

</script>

<style scoped>
#passwordBorder {
  width: calc(100% - 20px - 1em);
  max-height: calc(100vh - 2em - 2.2em - 2em - 1em - 20px);
  padding: 0.5em;
  user-select: none;
  overflow: hidden auto;
}

#passwordBorder h2 {
  font-size: 1em;
}

#passwordBorder input {
  width: calc(100% - 0.4em);
  padding: 0.2em 0.2em 0 0.2em;
  border-color: var(--softMagenta);
  border-style: solid;
  border-width: 0 0 3px 0;
  outline: none;
  font-family: Anybody Light;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 0.9em;
  background: var(--white);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

#passwordBorder input:focus {
  border-color: var(--magenta);
  background: var(--softMagenta);
}
</style>
