<template>
  <div id="home">
    <nav>
      <router-link to="/">Dashboard</router-link>
      <router-link to="/calendar">Kalender</router-link>
    </nav>
    <router-view id="content" />
  </div>
</template>

<script>
export default {
  name: 'HomeView'
}
</script>

<style scoped>
nav {
  width: 100%;
  background: var(--softMagenta);
  line-height: 1;
  height: 2em;
}

nav a {
  display: inline-block;
  width: calc(50% - 1em);
  padding: 0.5em;
  text-align: center;
  font-family: Anybody;
  color: var(--magenta);
  border-radius: 1em;
  text-decoration: none;
}
nav a:first-of-type {
  border-radius: 0 1em 1em 0;
}
nav a:last-of-type {
  border-radius: 1em 0 0 1em;
}

nav a.router-link-exact-active {
  color: var(--yellow);
  background: var(--magenta);
  font-family: Anybody ExtraBold;
}

#content {
  margin: 1em;
  width: calc(100vw - 2em);
  height: calc(100vh - 4em);
  overflow: hidden auto;
}
</style>
